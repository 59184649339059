import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { t } from "react-native-tailwindcss";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import { ScrollView } from "react-native-gesture-handler";
import { useQueryClient } from "react-query";
import { useToast } from "react-native-toast-notifications";
import AccordionCard from "../../../organisms/AccordionCard";
import Box from "../../../atoms/Box";
import Button from "../../../atoms/Button";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import { HCenterStack } from "../../../layout/HStack";
import { LineTitle } from "../../../molecules/LineTitle";
import { MediumText } from "../../../atoms/MediumText";
import { setRoundupAmount } from "../../../../services/userService";
import { ApiError, getErrorMessage } from "../../../../services/api/api";
import { QueryKey } from "../../../../services/api/query";
import { useUser } from "../../../../context/userContext";
import { useAccounts } from "../../../../context/accountsContext";

const StyledRoundupAmountSelectorContainer = styled(HCenterStack)`
  width: 100%;
`;

export const RoundupAmounts = (): React.ReactElement => {
  const toast = useToast();
  const { user } = useUser();
  const { accounts } = useAccounts();
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const queryClient = useQueryClient();
  const [roundupAmountCents, setRoundupAmountCents] = useState<number>(
    user?.roundupAmountCents ?? 0,
  );

  useEffect(() => {
    setRoundupAmountCents(user?.roundupAmountCents ?? 0);
  }, [user]);

  const setRoundups = async (amount: number): Promise<void> => {
    try {
      await setRoundupAmount(amount);
      queryClient.invalidateQueries(QueryKey.User);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    }
  };

  const toggleSwitch = () => {
    if (roundupAmountCents > 0) {
      setRoundupAmountCents(0);
    } else {
      setRoundupAmountCents(100);
    }
  };

  return (
    <AccordionCard
      checked={!!roundupAmountCents}
      toggleSwitch={toggleSwitch}
      style={[t.mB6, t.bgWhite]}
      title={`Round-ups ${roundupAmountCents ? "on" : "off"}`}
    >
      <LineTitle title="Amount" />
      <StyledRoundupAmountSelectorContainer>
        {[
          {
            title: "$1",
            value: 100,
          },
          {
            title: "$2",
            value: 200,
          },
          {
            title: "$5",
            value: 500,
          },
        ].map((b, i) => (
          <Box
            title={b.title}
            checked={b.value === roundupAmountCents}
            onPress={() => {
              setRoundupAmountCents(b.value);
              setRoundups(b.value);
            }}
            key={i.toString()}
          />
        ))}
      </StyledRoundupAmountSelectorContainer>
      <View style={t.mT4}>
        <LineTitle title="Enabled Accounts" />
      </View>
      <ScrollView contentContainerStyle={{ maxHeight: 100 }}>
        {accounts
          ?.filter(a => a.activeRoundUpsEnabled)
          .map((account, i) => (
            <MediumText key={i.toString()}>
              {account.name}: {account.accountNumber}
            </MediumText>
          ))}
      </ScrollView>
      <HCenterStack style={[t.pT4]}>
        <View style={t.wFull}>
          <Button
            label="Manage accounts"
            onPress={() => navigation.navigate("RoundupAccounts")}
          />
        </View>
      </HCenterStack>
    </AccordionCard>
  );
};
